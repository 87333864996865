import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="container">
      <div className="main-content__container">
        <div className="main-content__body">
          <h1 className="main-content__title">NOT FOUND</h1>
          <div className="main-content__content">
            <p>Oops this page doesn't exist. </p>
            <p>
              Why don't you check out the the{" "}
              <Link to="/posts">Post archive</Link>?{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
